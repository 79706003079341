import { JSONLoader } from 'ohzi-core';

export default class BearerJSONLoader extends JSONLoader
{
  constructor(resource_id, url, bearer_token)
  {
    super(resource_id, url);

    this.loader.setRequestHeader({ Authorization: `Bearer ${bearer_token}` });
  }
}
