export default class BaseRender
{
	constructor()
	{

	}

	render()
	{
		
	}

	on_enter(context, renderer)
	{

	}

	on_exit(context, renderer)
	{
		
	}

	resize()
	{
		
	}

}
