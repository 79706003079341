import { ResourceBatch } from 'ohzi-core';
import BearerJSONLoader from './js/decorators/BearerJSONLoader';

// This is the beginning of the js execution.
// This file take cares of load the require assets
// and start the app when they are loaded

let is_mobile = navigator.userAgent.match(/(iPhone|iPod|iPad|Android|playbook|silk|BlackBerry|BB10|Windows Phone|Tizen|Bada|webOS|IEMobile|Opera Mini)/);
let is_ios = navigator.userAgent.match(/(iPhone|iPod|iPad)/);
let is_ipad = navigator.userAgent.match(/(iPad)/);

let loader_view = document.querySelector('.loader');
let unauthorized_view = document.querySelector('.unauthorized');
let progress_bar = document.querySelector('.loader__progress-bar-fill');
let second_step = false;

let api_parameters = {
  is_mobile: is_mobile,
  is_ios: is_ios,
  is_ipad: is_ipad
};

/** on_api_ready is called when all resources has been loaded successfully. on_api_ready initialises the viewApi */
let on_api_ready = () =>
{
  loader_view.classList.add('hidden');

  ViewApi.init(api_parameters);
  ViewApi.resource_loading_completed();

  // Here we are calling MainApplication.start()
  // when all resources are loaded
  ViewApi.start();

  window.addEventListener('resize', function()
  {
    ViewApi.resize_canvas();
  });

  window.addEventListener('orientationchange', function()
  {
    ViewApi.on_orientation_change();
  });

  window.app = ViewApi.app;
};

let on_config_ready = () =>
{
  second_step = true; // second_step is true to indicate that we on_config_ready step.

  let config = ViewApi.resource_container.get_resource('config');

  let batch = new ResourceBatch();

  batch.add_texture('fisheye_tex',  'textures/snapshot.jpg');
  batch.add_texture('point',        'textures/point.png');
  batch.add_json('camera_settings', 'data/camera_settings.json');

  batch.add_loader(new BearerJSONLoader('cameras', `https://${config.cameras_api_ip}/cameras`, ViewApi.role_manager.bearer_token));
  // batch.add_loader(new BearerJSONLoader('webrtc_cameras', `https://dev-cdb.defender-ai.net/cameras`, ViewApi.role_manager.bearer_token));

  // batch.add_json('cameras', 'data/cameras_example.json');

  batch.load(ViewApi.resource_container);

  check_resource_loading(batch, on_api_ready);
};

/** on_auth_ready is the first function that is called when role manager has succesfully initialised */
let on_auth_ready = () =>
{
  if (ViewApi.role_manager.authenticated)
  {
    /** We creating a new batch of resource loaders each function on the Resource batch class accepts
     * two arguments the first is the id of the resource that we want to load and the second is the path
     * of the file or the url of the resources that we like to load. Calling batch.load will load all
     * the resources to the resource_container which acts as a global state management to access those
     * loaded resources through their id from anywhere in the app, by importing the ResourceContainer.
     *
      */
    let batch = new ResourceBatch();
    // batch.add_json('config', 'data/config.json'); // this is the static way of loading the config of the the user. Should keep this line commented until we have properly tested the dynamic one.
    ViewApi.resource_container.set_resource('config',ViewApi.role_manager.config); //load the user the dynamic use config to the resource container

    batch.load(ViewApi.resource_container);

    check_resource_loading(batch, on_config_ready);
  }
  else
  {
    loader_view.classList.add('hidden');
    unauthorized_view.classList.remove('hidden');
  }
};

let check_resource_loading = (batch, on_resources_loaded) =>
{
  if (second_step) // second_step is true when on_config_ready has been called
  {
    progress_bar.style.width = `${batch.get_progress() * 100}%`;
  }

  if (batch.loading_finished)
  {
    if (batch.has_errors)
    {
      batch.print_errors();
    }
    else
    {
      on_resources_loaded();
    }
  }
  else
  {
    setTimeout(function()
    {
      check_resource_loading(batch, on_resources_loaded);
    }, 200);
  }
};

let check_auth_status = (on_auth_ready) =>
{
  if (ViewApi.role_manager.initialized)
  {
    on_auth_ready();
  }
  else
  {
    setTimeout(function()
    {
      check_auth_status(on_auth_ready);
    }, 50);
  }
};

ViewApi.role_manager.init();
check_auth_status(on_auth_ready);
