import Arrow from '/primitives/Arrow';
import Cube from '/primitives/Cube';
import HorizontalPlane from '/primitives/HorizontalPlane';
import Sphere from '/primitives/Sphere';
import VerticalPlane from '/primitives/VerticalPlane';

export default {
  Arrow: Arrow,
  Cube: Cube,
  HorizontalPlane: HorizontalPlane,
  Sphere: Sphere,
  VerticalPlane: VerticalPlane
}
