export default class BaseApplication
{
	start() {}
	post_start() {}
	end() {}
	update() {}
	on_post_render() {}
	on_pre_render() {}
	resources_fully_loaded() {}
}
